
import {defineComponent, ref, Ref} from 'vue'
import {ContentBlockBackground, VideoWithText} from '@/types/graphql.sdk'
import RichText from '@/components/blocks/core/RichText.vue';

export default defineComponent({
    name: 'VideoWithText',
    components: {RichText},
    props: {
        bgColor: Object as () => ContentBlockBackground,
        videoWithText: Object as () => VideoWithText,
    },
    setup(props) {
        const showVideoModal: Ref<boolean> = ref(false);
        const handleModal = () => {
            if (!showVideoModal.value){
                showVideoModal.value = true
                document.body.style.overflow = 'hidden'
            } else {
                showVideoModal.value = false
                document.body.style.overflow = 'visible'
            }

        }

        return {
            video: props.videoWithText?.video,
            content: props.videoWithText?.content,
            showVideoModal,
            handleModal
        }
    },
    unmounted() {
        document.body.style.overflow = 'visible'
    }
});

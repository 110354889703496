
import { h } from 'vue';
import { ContentReference } from '@/types/graphql.sdk'

export interface TextChildren {
    text: string
    type?: string
    children?: TextChildren[]
    referenceId?: string | null
    isBold?: boolean
    isItalic?: boolean
    isUnderlined?: boolean
    isCode?: boolean
    isStruckThrough?: boolean
}

export interface Child {
    type: string
    level?: number
    children: TextChildren[]
}

export default {
    props: {
        parts: {
            type: Array,
            required: false,
            default: () => []
        },
        json: {
            type: String,
            required: false
        },
        wrapperElement: {
            type: String,
            default: 'div'
        },
        wrapperClasses: {
            type: String,
            required: false,
            default: null
        }
    },
    render(): any {
        // @ts-ignore
        let parts: { json: string }[] = this.parts
        // @ts-ignore
        const json: string | undefined = this.json

        if (Array.isArray(parts) === false) {
            return []
        }

        if (json) {
            parts = [{ json }]
        }

        const blockParts = parts.map((part) => {
            // @ts-ignore
            const references: ContentReference[] = part.references || []
            let dataJSON

            try {
                dataJSON = JSON.parse(part.json)
            } catch (e) {
                dataJSON = { children: [] }
            }

            if (dataJSON.children && Array.isArray(dataJSON.children)) {
                return dataJSON.children.map((child: Child) => {
                    let element = null
                    const attrs = {}
                    if (child.type === 'heading') {
                        element = 'h' + child.level
                    } else if (child.type === 'paragraph') {
                        element = 'p'
                    } else if (child.type === 'unorderedList') {
                        element = 'ul'
                    } else if (child.type === 'orderedList') {
                        element = 'ol'
                    }
                    const prepareChild = (child: TextChildren) => {
                        let childNodes = child.text
                        if (child.children && child.children.length) {
                            // @ts-ignore
                            childNodes = child.children.map(prepareChild)
                        }

                        if (child.type === 'listItem') {
                            return h('li', childNodes)
                        }

                        if (child.type === 'orderedList') {
                            return h('ol', childNodes)
                        }

                        if (child.type === 'unorderedList') {
                            return h('ul', childNodes)
                        }

                        if (child.type === 'link') {
                            let ref = null
                            if (child.referenceId) {
                                ref = references.find( (r) => r.id === child.referenceId) || null
                                if (ref === null) return child.text
                            }
                            return h('div', childNodes)
                        }

                        let tag = null

                        if (child.isBold) tag = 'strong'
                        if (child.isUnderlined) tag = 'u'
                        if (child.isItalic) tag = 'i'
                        if (child.isCode) tag = 'code'
                        if (child.isStruckThrough) tag = 'strike'

                        return tag ? h(tag, childNodes) : childNodes
                    }
                    if (element) {
                        return h(element, attrs, child.children.map((child) => prepareChild(child)))
                    } else {
                        // @ts-ignore
                        return prepareChild(child)
                    }
                })
            } else {
                return []
            }
        })

        // @ts-ignore
        return h(this.wrapperElement, { class: this.wrapperClasses }, blockParts);
    }
}
